<template>
  <div></div>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';

export default {

  mixins: [UserMixin],

  async mounted() {

    await this.doUserLogout()
  },

  methods: {

    async doUserLogout() {

      let apiHolder = await this.sendApiPost('api/user/logout');

      if (!apiHolder.isSuccess())
        return;

      this.deleteUserAuth();

      await this.redirect('/');
    }

  }
}
</script>

<style scoped>

</style>